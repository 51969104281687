<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <!--begin: Wizard Form-->

                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>Countries</h4>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button class="btn btn-primary" @click="addCountry">Add Country</button>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="">
                                            <table class="table">
                                                <thead>
                                                <th>Name</th>
                                                <th>Phone Code</th>
                                                <th>Prefix</th>
                                                <th>Action</th>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(country, index) of countries" :key="index">
                                                    <td>{{index+1}}</td>
                                                    <td>{{country.title}}</td>
                                                    <td>{{country.phone_code}}</td>
                                                    <td>{{country.prefix}}</td>
                                                    <td>
                            <span class="cursor-pointer" @click="editCountry(country)">
                              <i class="fas fa-edit"></i>
                            </span>
                                                        <span class="cursor-pointer" @click="deleteCountry(country.id)">
                              <i class="ml-3 fas fa-trash"></i>
                            </span> <span class="cursor-pointer" @click="stateDetail(country.id)">
                              <i class="ml-3 fas fa-eye"></i>
                            </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                aria-controls="my-table"
                                                @change="getCountries"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-and-update ref="country"></create-and-update>
    </v-app>
</template>
<script>
    import CountryService from "@/core/services/address/CountryService";
    import CreateAndUpdate from "./CreateAndUpdate";

    const countryService = new CountryService();

    export default {
        components: {
            CreateAndUpdate
        },
        data() {
            return {
                countries: [],
                perPage: 25,
                currentPage: 1,
                rows: 0
            };
        },
        mounted() {
            this.getCountries();
        },
        methods: {
            getCountries() {
                countryService.paginate(this.currentPage).then(response => {
                    this.countries = response.data.data;
                    //   this.currentPage = response.data.meta.current_page
                    this.perPage = response.data.meta.per_page;
                    this.rows =
                        parseInt(response.data.meta.last_page) * parseInt(this.perPage);
                });
            },
            deleteCountry(id) {
                countryService.delete(id).then(response => {
                    this.getCountries();
                });
            }, editCountry(country) {
                this.$refs['country'].showModal(country.id)
            }, addCountry() {
                this.$refs['country'].showModal()
            }, stateDetail(id) {
                this.$router.push({name: "state-list", params: {id: id}})
            }
        }
    };
</script>
