<template>
  <div>
    <b-modal id="modal-scrollable" size="md" ref="my-modal" hide-footer title="Add/Edit Country">
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" v-model="country.title" placeholder="Title" />
          <span class="text-danger" v-if="$v.country.title.$error">Title is required</span>
        </div>
        <div class="col-6 form-group">
          <input type="text" class="form-control" v-model="country.prefix" placeholder="Prefix" />
          <!-- <span class="text-danger" v-if="$v.country.title.$error">Title is required</span> -->
        </div>
        <div class="col-6 form-group">
          <input
            type="text"
            class="form-control"
            v-model="country.phone_code"
            placeholder="Phone Code"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <v-btn
                  class="text-primary"
                  depressed
                  block
                  @click="hideModal"
          >Cancel
          </v-btn>
        </div>
        <div class="col-6">
          <v-btn
                  class="text-primary btn btn-primary"
                  depressed
                  block
                  @click="createOrUpdate"
                  :loading="isBusy"
          >Save
          </v-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import CountryService from "@/core/services/address/CountryService";
const countryService = new CountryService();

export default {
  validations: {
    country: {
      title: { required }
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      country: {
        title: null
      }
    };
  },
  methods: {
    showModal(id = null) {
      if (id) {
        this.edit = true;
        this.getCountry(id);
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getCountry(id) {
      countryService.show(id).then(response => {
        this.country = response.data.country;
      });
    },

    createOrUpdate() {
      this.$v.country.$touch();
      if (this.$v.country.$error) {
        setTimeout(() => {
          this.$v.country.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateCountry();
        } else {
          this.createCountry();
        }
      }
    },
    updateCountry() {
        this.isBusy = true;
      countryService
        .update(this.country.id, this.country)
        .then(response => {
          this.isBusy = false;
          this.resetForm();
        })
        .catch(error => {
          this.isBusy = false;
        });
    },
    createCountry() {
        this.isBusy = true;
      countryService
        .store(this.country)
        .then(response => {
          this.isBusy = false;
          this.resetForm();
        })
        .catch(error => {
          this.isBusy = false;
          // console.log(error);
        });
    },
    resetForm() {
      this.edit = false;
      this.country = {
        title: null
      };
      this.hideModal();
      this.$emit("refresh_album");
    }
  }
};
</script>
